
<template>
    <div class="sidebar">
        <!-- <div class="sidebar-backdrop" @click="closeSidebarPanel()" v-if="isPanelOpen">
        </div> -->
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script>
    import store from '../store';
    export default {
        methods: {
            closeSidebarPanel(){
              store.dispatch('T_SD_CLOSE')
            } 
        },
        computed: {
            isPanelOpen() {
                return store.state.sidebar
            }
        }
    }
</script>
<style>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }

    .sidebar-backdrop {
        background-color: rgba(0,0,0,.5);
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 1;
    }

    .sidebar-panel {
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 25px;
        overflow-y: auto;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        top: 175px;
        height: 60vh;
        z-index: 999;
        padding: 3rem 20px 2rem 20px;
        width: 350px;
    }
    .sidebar-panel:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
</style>