<template>
  <div
    class="bg-gray-400"
    style="height:100%;"
  >
    <Sidebar>
      <div v-if="isMiniScreen">
        <h4 class="font_black">
          CCTV {{ cameras.data[selectedIndexFullscreen].name }}
        </h4>
        <br>
        <div
          class="flex-none bg-gray-500 h-8 flex items-center justify-end px-2 text-white text-xs"
          :style="sizePop"
        >
          <span class="mr-auto">CCTV</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="Perbesar"
            placement="left"
          >
            <i
              class="cursor-pointer ri-fullscreen-exit-line ri-lg"
              @click="togglePseudoScreen"
            />
          </el-tooltip>
        </div>
        <div
          v-if="$store.getters.getCurrentAuthUser"
          :style="sizePop"
        >
          <iframe
            width="100%"
            :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${cameras.data[selectedIndexFullscreen].stream_id}`"
            frameborder="0"
            allowfullscreen
          />
          <br>
          <!-- <span class="font_black block text-xs font-bold">ALAMAT : {{cameras.data[selectedIndexFullscreen].address}}</span> -->
          <p class="font_black block text-xs font-bold">
            Alamat : {{ cameras.data[selectedIndexFullscreen].address }}
          </p>
        </div>
        <div
          v-else
          :style="sizePop"
        >
          <!--<AutoReloadImage
              :src="`http://${url_serv}/image?host=${cameras.data[selectedIndexFullscreen].host}&t=${$moment().unix()}`"
            />-->

          <iframe
            width="100%"
            :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${cameras.data[selectedIndexFullscreen].stream_id}`"
            frameborder="0"
            allowfullscreen
          />
            
          <br>
          <p class="font_black block text-xs font-bold">
            Alamat : {{ cameras.data[selectedIndexFullscreen].address }}
          </p>
        </div>
      </div>
    </Sidebar>
    <transition name="fade">
      <div
        v-if="showModal"
        class="modal"
      >
        <div
          class="modal__backdrop"
          @click="closeModal()"
        />

        <div class="modal__dialog">
          <div class="modal__header">
            <slot name="header" />
            <button
              type="button"
              class="ri-close-circle-line bg-black modal__close"
              @click="closeModal()"
            />
          </div>

          <div class="modal__body">
            <slot name="body" /> 
            <div class="flex-none bg-gray-500 h-8 flex items-center justify-end px-2 text-white text-xs">
              <span class="mr-auto">CCTV {{ cameras.data[selectedIndexFullscreen].name }}</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="Perkecil"
                placement="left"
              >
                <i
                  class="cursor-pointer ri-fullscreen-exit-line ri-lg"
                  @click="togglePseudoScreen()"
                />
              </el-tooltip>
            </div>
            <div v-if="$store.getters.getCurrentAuthUser">
              <iframe
                width="100%"
                height="500"
                :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${cameras.data[selectedIndexFullscreen].stream_id}`"
                frameborder="0"
                allowfullscreen
              />
            </div>
            <div v-else>
              <!--<AutoReloadImage
              :src="`http://${url_serv}/image?host=${cameras.data[selectedIndexFullscreen].host}&t=${$moment().unix()}`"
            />-->
              <iframe
                width="100%"
                height="500"
                :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${cameras.data[selectedIndexFullscreen].stream_id}`"
                frameborder="0"
                allowfullscreen
              />
            </div>
          </div>

          <div class="modal__footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
    <!-- <button @click="$store.mutations.TOGGLE_SIDEBAR">CLick</button> -->
    <el-input
      v-model="searchQuery"
      placeholder="Cari CCTV"
      class="border-none bg-black plc_hold"
      style="color: #ff0000;"
    />
    <!-- <input class="form-control" type="text" v-model="searchQuery" placeholder="Search" /> -->
    <div
      v-for="(item,index) in resultQuery"
      :key="item.index"
      class="flex items-center scctv-list"
    >
      <div
        v-if="index <= 5"
        class="ml-4"
      >
        <div
          class="text-black cursor-pointer"
          @click="showMarker(item.key, item.name)"
        >
          <span class="block text-sm font-bold">{{ item.name }}</span>
          <span class="block text-xs truncate w-40">{{ item.address }}</span>
        </div>
      </div>
    </div>
    <!-- <div v-if="!showModal"> -->
    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%; opacity:1"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @click="mapClick()"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control class="example-custom-control">
        <span><b>FILTER</b></span><br>
        <select
          v-model="selectKecamatan"
          @change="onChangeKec($event)"
        >
          <option>ALL</option>
          <option>KEDUNGKANDANG</option>
          <option>KLOJEN</option>
          <option>BLIMBING</option>
          <option>LOWOKWARU</option>
          <option>SUKUN</option>
        </select>
      </l-control>
      <l-polygon
        v-if="selectKecamatan == 'SUKUN' "
        :lat-lngs="data_kec_kel.latlngs[4].geometry.coordinates"
        :color="polygon.color0"
      />
      <l-polygon
        v-else-if="selectKecamatan == 'LOWOKWARU' "
        :lat-lngs="data_kec_kel.latlngs[3].geometry.coordinates"
        :color="polygon.color1"
      />
      <l-polygon
        v-else-if="selectKecamatan == 'BLIMBING' "
        :lat-lngs="data_kec_kel.latlngs[2].geometry.coordinates"
        :color="polygon.color2"
      />
      <l-polygon
        v-else-if="selectKecamatan == 'KLOJEN' "
        :lat-lngs="data_kec_kel.latlngs[1].geometry.coordinates"
        :color="polygon.color3"
      />
      <l-polygon
        v-else-if="selectKecamatan == 'KEDUNGKANDANG' "
        :lat-lngs="data_kec_kel.latlngs[0].geometry.coordinates"
        :color="polygon.color4"
      />
      <div v-else-if="selectKecamatan == 'ALL' ">
        <l-polygon
          :lat-lngs="data_kec_kel.latlngs[4].geometry.coordinates"
          :color="polygon.color0"
        />
        <l-polygon
          :lat-lngs="data_kec_kel.latlngs[3].geometry.coordinates"
          :color="polygon.color1"
        />
        <l-polygon
          :lat-lngs="data_kec_kel.latlngs[2].geometry.coordinates"
          :color="polygon.color2"
        />
        <l-polygon
          :lat-lngs="data_kec_kel.latlngs[1].geometry.coordinates"
          :color="polygon.color3"
        />
        <l-polygon
          :lat-lngs="data_kec_kel.latlngs[0].geometry.coordinates"
          :color="polygon.color4"
        />
      </div>
                
      <div 
        v-for="(cam,index) in cameras.data" 
        :key="cam.stream_id"
      >
        <l-marker 
          :key="index"
          ref="markersRef"
          :lat-lng="cameras.konten_lat[index+1]"
          @click="markerClick()"
        >
          <!-- <l-marker 
                  :lat-lng="cameras.konten_lat[index+1]"
                  :key="index"
                  ref="markersRef"
                  @click="t_sidebar()"
                   > -->
          <l-icon
            :key="index"
            :icon-size="dynamicSize[index+1]"
            :icon-url="iconImage"
          />
          <l-popup :style="sizePop">
            <div
              :class="'ri-eye-line cursor-pointer'"
              @click="innerClick(index)"
            >
              <span class="mr-auto"> CCTV {{ cam.name }}</span>    
            </div>
          </l-popup>
        </l-marker>
      </div>
    </l-map>
    <!-- </div> -->
  </div>
</template>


<script>
// import { raw_data } from '../../../utils/axiosNew'
import Sidebar from '../../../components/Sidebar.vue'
import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LPolygon, LControl, LIcon 
// LTooltip
 } from "vue2-leaflet";
//  import AutoReloadImage from '../../../components/AutoReloadImage.component';
import icon_cam from '../../../icons/camera-marker.png';

export default {
  name: "Example",
  components: {
    Sidebar,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolygon,
    LControl,
    LIcon,
    // AutoReloadImage,
  },
  data() {
    return {
        user: null,
        markerObjects: [],
        cameras: {
            data: [],
            konten_lat: [{}],
            // key: 0,
            active: [],
            fullscreen: null,
            max: 16,
            grid: [4,4],
            infoOpen: null
        },
        zoom: 13,
        searchQuery: null,
        selectKecamatan: "ALL",
        isFullscreen: false,
        isMiniScreen: false,
        selectedIndexFullscreen: null,
        data_kec_kel: this.$constData,
        // data_api: "none",
        polygon: {
          color0: 'green',
          color1: 'red',
          color2: 'blue',
          color3: 'purple',
          color4: 'yellow'
        },
        center: latLng(-7.9807,112.6322),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        withPopup: latLng(-8.0228173892344, 112.6276123316),
        //   withTooltip: latLng(7.981779115765, 112.63174579237),
        currentZoom: 11.5,
        currentCenter: latLng(-7.9807,112.6322),
        showParagraph: false,
        showModal: false,
        sizePop: "width: 300px",
        // st_map : "height: 100%; opacity: 1",
        url_serv : "proxy.cctv.malangkota.go.id",
        url_local : "localhost:62341",
        mapOptions: {
            zoomSnap: 0.5
        },
        icon: icon({iconUrl: icon_cam,
                    // iconSize: [27, 32],
                    iconAnchor: [16, 37]
                  }),
        dynamicSize: [{}],
        defSize: [{}],
        // dynamicSize: [27, 32],
        iconImage: icon_cam,
        showMap: true
    };
  },
  computed: {
    resultQuery(){
      if(this.searchQuery){
        let jo = this.cameras.data.map((item,key)=>{
            let hasil = [];
            // hasil['name'] = this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
            if (this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))) {
              hasil['name'] = item.name
              hasil['key'] = key
            }
            return hasil
        })
        let jo2 =  jo.filter((item)=>{
          return item.name
        })
        // console.log(jo2)
       return jo2
      }else{
        return null;
      }
    },
   
  },
  async mounted() {
    // const raw = await this.raw_data();
    // const raw = this.$constData;
    // this.data_kec_kel = raw
    // console.log(this.data_kec_kel);
    const cameras = await this.getCameras();
    this.cameras.data = cameras.records;
    for (let i = 0; i < this.cameras.data.length; i++) {
        this.cameras.konten_lat.push(latLng(this.cameras.data[i].latitude, this.cameras.data[i].longitude));
        this.dynamicSize.push([27, 32]);
        this.defSize.push([27, 32]);
    }
    // console.log(cameras);
    // console.log(this.$store.getters.getCurrentAuthUser);
    // const dataApi = raw_data()
    // this.data_api = dataApi
    // console.log(this.data_api);
    //marker
    this.$nextTick(() => {
      this.markerObjects = this.$refs.markersRef.map(ref => ref.mapObject);
      // console.log(this.markerObjects)
    });
  },
  methods: {
    t_sidebar() {
      // this.$store.actions.
      this.$store.dispatch('T_SD_OPEN')
      // console.log(this.$store.state.sidebar)
    },
    onChangeKec(event) {
      //marker
      this.isMiniScreen = false;
      this.$store.dispatch('T_SD_CLOSE')
      //simple
      const simple = (req) => {
        this.cameras.data.map((item,key)=>{
              if (req.toLowerCase().split(' ').every(v => item.address.toLowerCase().includes(v))) {
                this.dynamicSize[key+1] = [27, 32]
              }else{
                this.dynamicSize[key+1] = [0,0]
              }
            })
      }
      let kd = "KEDUNGKANDANG"
      let bl = "BLIMBING"
      let sk = "SUKUN"
      let kl = "KLOJEN"
      let lw = "LOWOKWARU"
      let all = "ALL"
      let val = event.target.value
      // console.log(val)
      if (val == kd) {
          simple(kd)
          // let hasil = []
          // hasil.dynamicSize = this.dynamicSize
          // hasil.defSize = this.defSize
          // console.log(hasil)
      }
      else if(val == bl){
        simple(bl)
      }else if(val == sk){
        simple(sk)
      }else if(val == kl){
        simple(kl)
      }else if(val == lw){
        simple(lw)
      }else if(val == all){
          this.dynamicSize = {...this.defSize}
          // let hasil = []
          // hasil.dynamicSize = this.dynamicSize
          // hasil.defSize = this.defSize
          // console.log(hasil)
      }

    },
    showAlert() {
      alert("Click!");
    },
    closeModal() {
      this.showModal = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    Modal() {
      this.showModal = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {
      this.showModal = true;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    getCameras() {
      return new Promise((resolve) => {
        const searchParams = new URLSearchParams();
        searchParams.append('filter', 'address,nis');
        searchParams.append('filter', 'latitude,nis');
        searchParams.append('filter', 'longitude,nis');
        searchParams.append('filter', 'stream_id,nis');
        searchParams.append('filter', 'status,eq,1');
        this.$request.get('records/cameras', { params: searchParams }).then(response => {
          resolve(response.data);
        })
      })
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    innerClick(index) {
      this.t_sidebar()
      this.sizePop = "width: 300px"
      this.isMiniScreen = true;
      this.selectedIndexFullscreen = index;
      // console.log(this.isMiniScreen);
      // console.log(this.selectedIndexFullscreen);
    },
    markerClick() {
      this.sizePop = "width: 300px"
      this.isMiniScreen = false;
      this.$store.dispatch('T_SD_CLOSE')
    },
    mapClick() {
      this.isMiniScreen = false;
      this.$store.dispatch('T_SD_CLOSE')
    },
    togglePseudoScreen() {
      this.isMiniScreen = !this.isMiniScreen;
      if (this.isMiniScreen) {
        this.$store.dispatch('T_SD_OPEN')
      }else{
        this.$store.dispatch('T_SD_CLOSE')
      }
      // this.isFullscreen = !this.isFullscreen;
      // this.markerObjects[this.selectedIndexFullscreen].openPopup();
      this.showModal = !this.showModal
      
     
    },
    showMarker(index, text) {
      //icon
      this.isMiniScreen = false;
      this.$store.dispatch('T_SD_CLOSE')
      this.selectKecamatan = "ALL"
      this.dynamicSize = {...this.defSize}
      // console.log(this.dynamicSize);
      // console.log(this.selectKecamatan);

      this.searchQuery = text
      this.markerObjects[index].openPopup();
    },
  }
};
</script>

<style lang="scss">
//sidebar
.font_black {
  color: rgb(0, 0, 0);
}
//control map
.example-custom-control {
  background: rgb(231, 231, 231);
  color: rgb(22, 20, 20);
  // padding: 1 1em;
  padding: 1em;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}
//hover search
.plc_hold ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(202, 198, 198);
  opacity: 0,5;
}
.scctv-list {
        // overflow-y: scroll;
    &:hover {
        background-color: darkgrey;
    }
}
//modal
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    // position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  &__close {
    width: 30px;
    height: 30px;
  }
  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

//maps
.el-tree-node__content {
  @apply py-6;
  padding-left: 0px !important;
}
.el-tree-node__content:hover,
.el-tree-node__content:focus {
  background: none;
}
.el-tree {
  background: transparent;
}
.leaflet-popup-content { 
     width:auto !important; 
}
.leaflet-popup-tip {
    width: 0px;
    height: 0px;
  }
.leaflet-container {
isolation: isolate;
}
</style>